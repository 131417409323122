import React from 'react';

const RedirectPage = () => {
    // Define os estilos inline
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: '0',
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
    };

    const boxStyle = {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '20px',
        maxWidth: '500px',
        width: '100%',
    };

    const headingStyle = {
        color: '#333',
    };

    const paragraphStyle = {
        color: '#666',
        marginBottom: '20px',
    };

    const buttonStyle = {
        display: 'inline-block',
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#007BFF',
        border: 'none',
        borderRadius: '5px',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3',
    };

    return (
        <div style={containerStyle}>
            <div style={boxStyle}>
                <h2 style={headingStyle}>Estamos Atualizando!</h2>
                <p style={paragraphStyle}>O site Allfood Online foi movido para a área técnica no site da Dalcatech.</p>
                <p style={paragraphStyle}>Clique no botão abaixo para ser redirecionado para o novo site:</p>
                <a 
                    href="https://www.dalcatech.com.br/#/allfoodonline" 
                    style={buttonStyle} 
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Ir para Dalcatech
                </a>
            </div>
        </div>
    );
};

export default RedirectPage;
